
  
export default {
    Home:{
        name:'欢迎，我是徐雄',
        introduction:'一名位于上海',
        job:'不知名的前端开发者',
        goodMorning:'早上好',
        goodAfternoon:'下午好',
        goodEvening:'晚上好',
        goodNight:'还不睡呢，夜猫子',
        goodWeekend:'周末愉快',
        nav_home:'首页',
        nav_about:'关于我',
        nav_project:'项目',
        nav_Resume:'简历',
        meta_name:'徐雄',
        meta_city:'上海',
        meta_country:'中国',
        meta_job:'前端开发工程师',
        ToggleTheme:'切换主题',
        ToggleLang:'切换语言',
    },
    Project:{
        portfolio_desc:'该个人网站通过React框架实现了组件化开发,使用Material UI设计系统快速实现界面美观。网站内容全面展示个人信息,网页采用响应式设计,在各设备上浏览体验优秀。',
        onboard_desc:'员工入职工作台，该项目使用elementUI,Vue2.0,axios等技术栈开发，实现了员工入职的流程化管理。',
        onboard_m_desc:'员工入职APP,该项目使用Vant2，Vue2.0,axios等技术栈开发，实现了员工入职的流程化管理，同时支持移动端',
        learn_desc:'员工学习管理系统,该项目使用Vant2，Vue2.0,axios等技术栈开发，实现了员工入职的流程化管理，同时支持移动端',
        black_desc:'黑洞云APP，该项目使用uni-app，axios等技术栈开发，实现多端统一管理。',
    },
    About:{
        title:"关于我",
        introduction_job:'嗨！我是一名从事前端开发3年多的程序猿，目前就职于上海某互联网公司。',
        introduction_like:'我喜欢一些炫酷的东西，同时喜欢研究新的技术，喜欢分享新的技术。',
        introduction_skill:'我擅长使用',
        introduction_build:'构建引人瞩目且稳定的网站，始终追求用户友好的在线体验。',
        introduction_spare:'除了工作，我也会用一些其他技术，例如',
        introduction_F_project:'等其他工具打造个人喜欢的炫酷网站。(比如这个portfolio)',
        introduction_contact:'热烈欢迎与有共同爱好的人互相交流。另外如果你需要一位有着工作经验、充满激情的开发者，非常期待能与您合作！',
    }
    }
